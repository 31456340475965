



















import { Component } from 'vue-property-decorator'

import { AllowedImageWidth, ImageData } from '../../../contexts'

import { toImageProps } from '../../../front/shared/support'
import { ImageProps } from '../../../dsl/atoms/Image'

import { AbstractModuleUi } from '../../abstract/ui'

import { HeadingData } from '../../partials'

import { AdvertiseBoxItem, AdvertiseBoxModule } from '../AdvertiseBox.contract'

/**
 * @author Magda Pietruszewska <magda.pietruszewska@movecloser.pl>
 */
@Component<AdvertiseBoxModuleUi>({ name: 'AdvertiseBoxModuleUi' })
export class AdvertiseBoxModuleUi extends AbstractModuleUi<AdvertiseBoxModule> {
  /**
   * Determines is `AdvertiseBoxModuleUi` should render.
   */
  public get shouldRender (): boolean {
    return this.content.items.length > 0
  }

  /**
   * Determines is `AdvertiseBox` has heading.
   */
  public get hasHeading (): boolean {
    return typeof this.content.heading !== 'undefined' && this.content.heading !== null
  }

  /**
   * Determines heading.
   */
  public get heading (): HeadingData | null | undefined {
    return this.content.heading
  }

  /**
   * Determines items.
   */
  public get items (): AdvertiseBoxItem[] {
    if (this.content.items.length === 0) {
      return []
    }

    return this.content.items
  }

  /**
   * Translate `ImageData` to `ImageProps`.
   */
  public translateToImageProps (image: ImageData): ImageProps {
    return toImageProps(image, undefined, AllowedImageWidth.FullWidth)
  }
}

export default AdvertiseBoxModuleUi
